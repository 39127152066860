.article-content .sidebar {
  height: 420px;
}

.post_share_block ul {
  display: flex;
  justify-content: center;
}

.post_share_block {
  margin-top: 35px;
}

.post_share_block .fb-color {
  background-color: #516eab;
}
.post_share_block .tw-color {
  background-color: #29c5f6;
}
.post_share_block .g-color {
  background-color: #0077b5;
}
.post_share_block .p-color {
  background-color: #ca212a;
}

.post_share_block ul li i {
  color: #fff;
  border-right: 2px solid #fff;
  padding-right: 10px;
  font-size: 16px;
}
.post_share_block ul li a span {
  color: #fff;
  padding-left: 4px;
  font-size: 16px;
}

.post_share_block ul li {
  display: flex;
  justify-content: center;
  width: 136px;
  height: 40px;
  font-weight: 400;
  margin: 3px;
  color: #fff;
}

.article-title {
  max-width: 70%;
  margin: 0 auto;
  font-family: Oswald, sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: -2px;
  text-align: center;
}

.article-item {
  margin-top: 20px;
  color: #222;
}


.article-item .fragment {
  margin: 46px 0 25px;
  color: #222;
}

.article-item .fragment h3 {
  font-size: 28px;
  font-family: Oswald, sans-serif;
  line-height: 2.11;
  color: #000;
  font-weight: 700;
}

.article-content .article-item img {
  border: 0;
  height: auto;
  margin: 15px 0 10px;
  max-width: 100%;
  padding: 0;
}

.article-item figcaption {
  position: relative;
  text-align: center;
  font-size: 11px;
  font-style: italic;
  padding-bottom: 20px;
}

.article-item figcaption:after {
  content: "";
  width: calc(100% - 30px);
  position: absolute;
  bottom: 0;
  left: 15px;
  border-bottom: 1px solid #dcdcdc;
}

.advertising-block {
  position: relative;
  width: 100%;
  background: #dcdcdc;
  margin-top: 10px;
  min-height: 90px;
}

.advertising-block:after {
  content: "";
  width: calc(100% - 30px);
  position: absolute;
  bottom: -26px;
  left: 15px;
  border-bottom: 1px solid #dcdcdc;
}

.advertising-block > div {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}

.advertising-block .ad-title {
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  margin: 0;
  background: #dcdcdc;
}

.article-header {
  text-align: center;
}

.article-header-parent {
  margin-left: unset;
  margin-right: unset;
}

.article-header .title {
  max-width: 100%;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  clear: both;
  font-family: Oswald, sans-serif !important;
  color: #222;
}

.article-header > p {
  font-size: 28px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: #dd3131;
}
.article-header .article-info > span {
  color: #aaa;
  font-size: 20px;
}
.article-header a {
  font-size: 19px !important;
  color: #777 !important;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  text-decoration: none;
}
.article-header .pdate {
  font-size: 19px;
  font-weight: 400 !important;
  color: #777;
  font-family: Montserrat, sans-serif;
}
.article-header > h1 {
  margin: 0 auto;
}
.article-header > div > p {
  margin-top: 0;
  margin-bottom: 0;
  color: #dd3131;
  font-size: 28px;
  font-weight: 700;
}
.article-header p {
  margin-top: 50px;
}

.article-big-image {
  width: 100%;
  margin: 70px 0 50px;
}

.article-info.info-top {
  display: none;
}

@media (max-width: 400px) {
  .post_share_block ul li {
    width: 70px;
  }
}

@media (max-width: 425px) {
  .article-header .article-big-image {
    height: 345px;
    object-fit: cover;
  }

  .post_share_block ul li {
    width: 50px !important;
  }
}

@media (max-width: 768px) {
  .article-header:not(.part-width) .article-info.info-top {
    display: block;
  }

  .article-header:not(.part-width) .info-bottom {
    display: none;
  }

  .article-item .fragment {
    margin-top: 0;
  }

  .article-header {
    padding: 0 !important;
  }

  .article-header > div > p {
    font-size: 14px;
  }

  .article-big-image {
    margin: 15px 0 0 !important;
  }

  .part-width .article-big-image {
    margin-bottom: 0 !important;
    height: 389px;
    object-fit: cover;
  }

  .article-info {
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
  }

  .article-info .article-title {
    text-align: left;
    font-size: 24px;
    letter-spacing: -0.5px;
    margin: unset;
    max-width: unset;
  }

  .article-header .article-big-image {
    width: calc(100% - 30px);
    height: 389px;
    object-fit: cover;
  }

  .post_share_block ul {
    justify-content: flex-start;
    padding-left: 14px !important;
  }

  .article-header .article-info > span a,
  .article-header .article-info > span span {
    font-size: 11px !important;
  }

  .article-header .article-info > span {
    display: block;
    margin-top: -9px;
  }

  .post_share_block ul li {
    width: 90px;
  }

  .advertising-block {
    width: calc(100% + 30px);
    min-height: 250px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 46px;
  }
}

@media (max-width: 991px) {
  .article-content {
    margin-top: 0 !important;
  }

  .article-big-image {
    margin-top: 0;
  }
}

@media (max-width: 996px) {
  .post_share_block ul li a span {
    display: none;
  }

  .post_share_block ul li a i {
    border-right: unset;
  }

  .post_share_block ul {
    padding: 0;
  }

  .sidebar {
    display: none;
  }
}

@media (min-width: 1430px) {
  .article-header:not(.part-width) {
    width: 1420px;
    margin: 0 auto;
  }
}