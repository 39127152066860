.App {
  font-size: 20px;
  line-height: 2.11;
  color: #222222;
}

.content,
.container-part {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.content .article-content .article-base-content {
  padding-right: 15px;
  padding-left: 15px;
}

.loading-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

figcaption {
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  color: #444;
  font-family: Verdana,Geneva,sans-serif;
}

@media (min-width: 376px) {
  .content, .container-part {
    max-width: 540px;
  }
}

@media (min-width: 568px) {
  .content, .container-part {
    max-width: 720px;
  }
}

@media (min-width: 792px) {
  .content, .container-part {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .content, .container-part {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .content, .container-part {
    max-width: 1420px;
  }
}
