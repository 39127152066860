@import url("~bootstrap/dist/css/bootstrap.min.css");

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300&family=Oswald:wght@300;500;700&display=swap');


body {
  margin: 0;
  font-family: Montserrat,sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
